<template>
  <div class="container">
    <h1>Privacy Policy</h1>
    <p>
      The European Union has introduced a number of new laws to protect personal
      data inside and outside of the EU. We as a European company need to comply
      with the rules set forth in
      <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=EN#d1e40-1-1">EU
        regulation (EU) 2016/679</a>.
    </p>

    <h2>Introduction</h2>
    <p>
      We don’t want to put up a lot of complicated legal text here. We believe
      you won’t read it or be quickly bored by it. So we try to keep it short
      and to the point and use some common sense with regards to General Data
      Protection Regulation (GDPR). This Data Protection Policy applies to all
      services, websites and other data processing facilities under the
      second-level domain caimito.eu.
    </p>

    <h3>Contact details</h3>
    <p>
      Caimito Agile Life S.L.<br />
      Apartado de Correos #30<br />
      14440 Villanueva de Cordoba<br />
      Spain
    </p>
    <p>
      Legal representative: Stephan Schwab
    </p>

    <h2>We value your privacy</h2>
    <p>
      We are not interested in your data. Neither you or your data is our
      product. If you decide to buy a product or service from us, we appreciate
      your business. We have no reason to sell any information about you or
      disclose it to anybody except when required by law. That’s why we always
      ask you for the bare minimum of information necessary to perform the
      transaction.
    </p>

    <h2>
      How we collect information and what information we keep
    </h2>
    <p>
      When you interact with us we only ask for the bare minimum of data needed.
      For example, we ask for your email address so that we can reply to your
      inquiry. We store such information for as long as necessary and for as
      long as our relationship exists.
    </p>
    <h3 class="text-lg mt-2">Technical information</h3>
    <p>
      Our website does make use of cookies to function properly. Cookies are
      small bits of data that are stored exclusively on your own device and
      cannot be read by anyone but the service that placed them there. Your
      device allows you to see and manage the cookies stored.
    </p>
    <p>
      In order to monitor the health of our systems extensive logging happens.
      The information stored in server logs does not refer to people but to
      devices or software components. When you access our website, such as for
      reading this text, your request has been logged with the IP address
      currently assigned to your device.
    </p>
    <p>
      We also make use of the <a href="https://marketingplatform.google.com">Google Marketing Platform</a> to better
      optimize our services for an improved experience.
    </p>
    <h2>Where we store your information</h2>
    <p>
      Your information is stored on our own systems. Critical information, such
      as a credit card number for subsequent purchases, is stored encrypted and
      only authorized personel can access it.
    </p>
    <h2>What we use your information for</h2>
    <p>
      When we ask you to provide information we also tell you why and for which
      purpose. There is no data collection invisible to you except the technical
      data mentioned above.
    </p>
    <h2>Data removal</h2>
    <p>
      You are in control of the data stored by us. For that purpose we offer you
      a service to see what we have stored and you are free to remove any of it
      at any point in time. Please keep in mind that removing data might require
      you to enter it again when you like to use a service in the future.
    </p>
    <h2>
      Who is responsible for your information at our company
    </h2>
    <p>
      The legal representantive of Caimito Agile Life S.L. is responsible for
      your information.
    </p>
    <h2>How to complain</h2>
    <p>
      If you wish to complain about anything with regards to your personal data,
      please send email to info@caimito.net and we will address your concern
      without delay.
    </p>
  </div>
</template>
